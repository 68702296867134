// import { NgModule } from "@angular/core";
// import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

// const routes: Routes = [
//   {
//     path: "",
//     redirectTo: "",
//     pathMatch: "full",
//   },
//   {
//     path: "home",
//     pathMatch: "full",
//     loadChildren: () =>
//       import("./pages/home/home.module").then((m) => m.HomePageModule),
//   },
//   {
//     path: "info",
//     pathMatch: "full",
//     loadChildren: () =>
//       import("./pages/gaxinfo/gaxinfo.module").then((m) => m.GAXInfoPageModule),
//   },
//   {
//     path: "about",
//     pathMatch: "full",
//     loadChildren: () =>
//       import("./pages/about/about.module").then((m) => m.AboutPageModule),
//   },
//   {
//     path: "events",
//     pathMatch: "full",
//     loadChildren: () =>
//       import("./pages/events/events.module").then((m) => m.EventsPageModule),
//   },
//   {
//     path: "sponsors",
//     pathMatch: "full",
//     loadChildren: () =>
//       import("./pages/sponsors/sponsors.module").then(
//         (m) => m.SponsorsPageModule
//       ),
//   },
//   {
//     path: "contact",
//     pathMatch: "full",
//     loadChildren: () =>
//       import("./pages/contact/contact.module").then((m) => m.ContactPageModule),
//   },
//   {
//     path: "sponsors",
//     loadChildren: () =>
//       import("./pages/sponsors/sponsors.module").then(
//         (m) => m.SponsorsPageModule
//       ),
//   },
//   //   {
//   //     path: 'folder/:id',
//   //     loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
//   //   }
// ];

// @NgModule({
//   imports: [
//     RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
//   ],
//   exports: [RouterModule],
// })
// export class AppRoutingModule {}

import { NgModule } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  PreloadAllModules,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from "@angular/router";
import { GAXInfoPageModule } from "./pages/gaxinfo/gaxinfo.module";
import { RedirectGuard } from "./auth/Redirect.guard";

const routes: Routes = [
  // {
  //   path: "",
  //   redirectTo: "gax",
  //   pathMatch: "full",
  // },
  {
    path: "",
    redirectTo: "gax",
    pathMatch: "full",
  },
  {
    path: "news",
    pathMatch: "full",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "gax",
    pathMatch: "full",
    loadChildren: () =>
      import("./pages/gaxinfo/gaxinfo.module").then((m) => m.GAXInfoPageModule),
  },
  {
    path: "about",
    pathMatch: "full",
    loadChildren: () =>
      import("./pages/about/about.module").then((m) => m.AboutPageModule),
  },
  {
    path: "sponsors",
    pathMatch: "full",
    loadChildren: () =>
      import("./pages/sponsors/sponsors.module").then(
        (m) => m.SponsorsPageModule
      ),
  },
  {
    path: "contact",
    pathMatch: "full",
    loadChildren: () =>
      import("./pages/contact/contact.module").then((m) => m.ContactPageModule),
  },
  {
    path: "events",
    pathMatch: "full",
    loadChildren: () =>
      import("./pages/events/events.module").then((m) => m.EventsPageModule),
  },
  {
    path: "locations",
    pathMatch: "full",
    loadChildren: () =>
      import("./pages/locations/locations.module").then(
        (m) => m.LocationsPageModule
      ),
  },
  {
    path: "boardgames",
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl:
        "https://gameshelf.io/user/lambrace?columns=alphabet,players,weight,minPlayTime,maxPlayTime,bggRating,playersBest&sort=alphabet,asc&table&players-s-s&maxPlayTime-m-s&type-i-h=Expansion&status-i-s=Owned",
    },
  },
  // {
  //   path: "rpgday",
  //   loadChildren: () =>
  //     import("./pages/rpgday/rpgday.module").then((m) => m.RpgdayPageModule),
  // },
];
@NgModule({
  providers: [
    {
      provide: "externalUrlRedirectResolver",
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      },
    },
  ],
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
