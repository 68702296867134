import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  public appPages = [
    { title: "GAX", url: "gax", icon: "game-controller" },
    // { title: "RPG Day", url: "rpgday", icon: "dice" },
    { title: "News Feed", url: "news", icon: "newspaper" },
    { title: "About Us", url: "about", icon: "person" },
    { title: "Sponsors", url: "sponsors", icon: "star" },
    { title: "Contact Us", url: "contact", icon: "mail" },
  ];
  public gaxPages = [
    // { title: "GAX Info", url: "gax", icon: "dice" },
    { title: "Events", url: "events", icon: "calendar" },
    { title: "Locations", url: "locations", icon: "location" },
    { title: "Board Games List", url: "boardgames", icon: "dice" },
  ];
  constructor(private router: Router) {}

  ngOnInit(): void {}

  // public isGAXPage(): boolean {
  //   return this.router.url === "/gax";
  // }
}
